// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-content-page-tsx": () => import("/opt/build/repo/src/templates/contentPage.tsx" /* webpackChunkName: "component---src-templates-content-page-tsx" */),
  "component---src-pages-404-tsx": () => import("/opt/build/repo/src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-home-tsx": () => import("/opt/build/repo/src/pages/home.tsx" /* webpackChunkName: "component---src-pages-home-tsx" */),
  "component---src-pages-index-tsx": () => import("/opt/build/repo/src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-map-tsx": () => import("/opt/build/repo/src/pages/map.tsx" /* webpackChunkName: "component---src-pages-map-tsx" */)
}

